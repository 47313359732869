<template>
  <input v-model="value" class="uk-range" type="range" v-bind="inputAttrs" />
</template>

<script>
import RangeInputMixin from "@/client/extensions/mixins/baseComponents/form/controls/input/rangeInput.js";

export default {
  mixins: [RangeInputMixin],
};
</script>

<style scoped lang="scss"></style>
